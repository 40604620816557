import { useState, useEffect } from 'react';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import pro1 from '../assets/images/pr1.jpeg';
import pro3 from '../assets/images/pr13.jpeg';
import pro4 from '../assets/images/pr19.jpeg';
import Gallery1 from '../components/Gallery1';
import Gallery3 from '../components/Gallery3';
import Gallery4 from '../components/Gallery4';

import AOS from 'aos';
import 'aos/dist/aos.css';
export default function Projects() {
  const currentLanguageCode = cookies.get('i18next');

  const [isArabic, setIsArabic] = useState(
    currentLanguageCode === 'ar' ? true : false
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (currentLanguageCode === 'ar') {
      setIsArabic(true);
    } else {
      setIsArabic(false);
    }
  }, [currentLanguageCode, t]);

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 1000,
      easing: 'ease',
    });
  });

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  if (modal) {
    document.body.classList.add('active-modal');
  } else {
    document.body.classList.remove('active-modal');
  }
  // /////////////////////////////

  const [modal2, setModal2] = useState(false);
  const toggleModal2 = () => {
    setModal2(!modal2);
  };
  if (modal2) {
    document.body.classList.add('active-modal');
  } else {
    document.body.classList.remove('active-modal');
  }
  // /////////////////////////////

  const [modal3, setModal3] = useState(false);
  const toggleModal3 = () => {
    setModal3(!modal3);
  };
  if (modal3) {
    document.body.classList.add('active-modal');
  } else {
    document.body.classList.remove('active-modal');
  }
  // /////////////////////////////

  const [modal4, setModal4] = useState(false);
  const toggleModal4 = () => {
    setModal4(!modal4);
  };
  if (modal4) {
    document.body.classList.add('active-modal');
  } else {
    document.body.classList.remove('active-modal');
  }

  return (
    <div
      style={isArabic ? { direction: 'rtl' } : null}
      className='standerContener'>
      <div
        style={isArabic ? { direction: 'rtl' } : null}
        className='hotelpernc'
        data-aos='fade-up'>
        <>
          <div>
            <img
              width='auto'
              alt='hotel'
              className='hotelCardImg'
              src={pro1}
            />
          </div>

          <div className='hoteldetel cardivL'>
            <h3 className={isArabic ? 'arFont' : null}>{t('services6')}</h3>
            <h4 className={isArabic ? 'arFont' : null}> {t('services7')}</h4>
          </div>
          <div
            className={
              isArabic
                ? 'hotelpric hotelpricBorderAr'
                : 'hotelpric hotelpricBorder'
            }>
            <div className='MoreBtnContener'>
              <button
                onClick={toggleModal}
                className={
                  isArabic
                    ? 'arFont btn-hover butn hotelButnAr'
                    : 'btn-hover butn'
                }>
                {t('services5')}
              </button>
            </div>
          </div>
        </>
      </div>

      <div
        style={isArabic ? { direction: 'rtl' } : null}
        className='hotelpernc'
        data-aos='fade-up'>
        <>
          <div>
            <img
              width='auto'
              alt='hotel'
              className='hotelCardImg'
              src={pro3}
            />
          </div>

          <div className='hoteldetel cardivL'>
            <h3 className={isArabic ? 'arFont' : null}>{t('services11')}</h3>
            <h4 className={isArabic ? 'arFont' : null}> {t('services7')}</h4>
          </div>
          <div
            className={
              isArabic
                ? 'hotelpric hotelpricBorderAr'
                : 'hotelpric hotelpricBorder'
            }>
            <div className='MoreBtnContener'>
              <button
                onClick={toggleModal3}
                className={
                  isArabic
                    ? 'arFont btn-hover butn hotelButnAr'
                    : 'btn-hover butn'
                }>
                {t('services5')}
              </button>
            </div>
          </div>
        </>
      </div>
      <div
        style={isArabic ? { direction: 'rtl' } : null}
        className='hotelpernc'
        data-aos='fade-up'>
        <>
          <div>
            <img
              width='auto'
              alt='hotel'
              className='hotelCardImg'
              src={pro4}
            />
          </div>

          <div className='hoteldetel cardivL'>
            <h3 className={isArabic ? 'arFont' : null}>{t('services12')}</h3>
            <h4 className={isArabic ? 'arFont' : null}> {t('services8')}</h4>
          </div>
          <div
            className={
              isArabic
                ? 'hotelpric hotelpricBorderAr'
                : 'hotelpric hotelpricBorder'
            }>
            <div className='MoreBtnContener'>
              <button
                onClick={toggleModal4}
                className={
                  isArabic
                    ? 'arFont btn-hover butn hotelButnAr'
                    : 'btn-hover butn'
                }>
                {t('services5')}
              </button>
            </div>
          </div>
        </>
      </div>

      {modal && (
        <div className='modal'>
          <div
            onClick={toggleModal}
            className='overlay'></div>
          <div className='modal-content-big'>
            <div onClick={toggleModal}>
              <i className='fa-regular fa-circle-xmark abslooot'></i>
            </div>

            <div className='SingiRoomComtener'>
              <div className='cardivB SingiHotelDiv SinglRoomDiv'>
                <Gallery1 />
              </div>
            </div>
          </div>
        </div>
      )}

      {modal3 && (
        <div className='modal'>
          <div
            onClick={toggleModal3}
            className='overlay'></div>
          <div className='modal-content-big'>
            <div onClick={toggleModal3}>
              <i className='fa-regular fa-circle-xmark abslooot'></i>
            </div>

            <div className='SingiRoomComtener'>
              <div className='cardivB SingiHotelDiv SinglRoomDiv'>
                <Gallery3 />
              </div>
            </div>
          </div>
        </div>
      )}

      {modal4 && (
        <div className='modal'>
          <div
            onClick={toggleModal4}
            className='overlay'></div>
          <div className='modal-content-big'>
            <div onClick={toggleModal4}>
              <i className='fa-regular fa-circle-xmark abslooot'></i>
            </div>

            <div className='SingiRoomComtener'>
              <div className='cardivB SingiHotelDiv SinglRoomDiv'>
                <Gallery4 />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/logo.jpeg';
import { useSpring, animated } from 'react-spring';

export default function NavBar({ theme, switchTheme }) {
  const currentLanguageCode = cookies.get('i18next');

  const [isArabic, setIsArabic] = useState(
    currentLanguageCode === 'ar' ? true : false
  );

  const chengLang = () => {
    if (currentLanguageCode === 'en') {
      i18next.changeLanguage('ar');
    } else {
      i18next.changeLanguage('en');
    }
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (currentLanguageCode === 'ar') {
      setIsArabic(true);
    } else {
      setIsArabic(false);
    }
  }, [currentLanguageCode, t]);

  useEffect(() => {
    const siteHeader = function () {
      let doc = document.documentElement;
      let w = window;
      let curScroll;
      let prevScroll = w.scrollY || doc.scrollTop;
      let curDirection = 0;
      let prevDirection = 0;
      let header = document.getElementById('site-header');
      let toggled;
      let threshold = 200;

      let checkScroll = function () {
        curScroll = w.scrollY || doc.scrollTop;
        if (curScroll > prevScroll) {
          // scrolled down
          curDirection = 2;
        } else {
          //scrolled up
          curDirection = 1;
        }

        if (curDirection !== prevDirection) {
          toggled = toggleHeader();
        }

        prevScroll = curScroll;
        if (toggled) {
          prevDirection = curDirection;
        }
      };

      let toggleHeader = function () {
        toggled = true;
        if (curDirection === 2 && curScroll > threshold) {
          header.classList.add('hide');
        } else if (curDirection === 1) {
          header.classList.remove('hide');
        } else {
          toggled = false;
        }
        return toggled;
      };

      window.addEventListener('scroll', checkScroll);
    };
    siteHeader();
  }, []);

  const [hamburger, setHamburger] = useState(false);

  const pathname = window.location.pathname;

  const path = pathname === '/' ? 'home' : pathname.slice(1);
  const [activeItem, setActiveItem] = useState(path);

  const [showLinks, setShowLinks] = useState(false);
  const linksContainerRef = useRef({});
  const linksRef = useRef({});

  useEffect(() => {
    const linksHeight = linksRef.current.getBoundingClientRect().height;
    if (showLinks) {
      linksContainerRef.current.style.height = `${linksHeight}px`;
    } else {
      linksContainerRef.current.style.height = '0px';
    }
  }, [showLinks]);

  const handleClick = ({ name }) => {
    setHamburger(!hamburger);
    setActiveItem({ name });
    setShowLinks(!showLinks);
  };
  const properties = {
    sun: {
      r: 9,
      transform: 'rotate(40deg)',
      cx: 14,
      cy: 4,
      opacity: 0,
    },
    moon: {
      r: 5,
      transform: 'rotate(90deg)',
      cx: 30,
      cy: 0,
      opacity: 1,
    },
    springConfig: { mass: 4, tension: 250, friction: 35 },
  };
  const { r, transform, cx, cy, opacity } =
    theme === 'light' ? properties.sun : properties.moon;
  const svgContainerProps = useSpring({
    transform,
    config: properties.springConfig,
  });
  const centerCircleProps = useSpring({ r, config: properties.springConfig });
  const maskedCircleProps = useSpring({
    cx,
    cy,
    config: properties.springConfig,
  });
  const linesProps = useSpring({ opacity, config: properties.springConfig });

  const scrallToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <>
      <nav
        id='site-header'
        className='nav'>
        <div className={isArabic ? 'nav-center nav-centerAR ' : 'nav-center'}>
          <ul className='nav-header'>
            <div
              className='nav-header'
              style={{ direction: 'ltr' }}>
              <Link
                className='nav-link logo links'
                name='home'
                to='/'
                onClick={scrallToTop}
                active={activeItem.toString()}>
                <div className='logocontner wdwe'>
                  <img
                    src={logo}
                    alt='logo'
                    width='100%'
                    height='100%'
                  />
                </div>
              </Link>
              <h2 className='links'>
                <Link
                  name='home'
                  to='/'
                  active={activeItem.toString()}
                  className='logo links'
                  onClick={scrallToTop}>
                  <span className='sddddwdwd'>الصقور الذكية للتجارة</span>

                  <div
                    className='sddddwdwd2'
                    style={{ marginTop: 6, marginBottom: -6 }}>
                    Smart Falcons Trading CO.
                  </div>
                </Link>
              </h2>
            </div>

            <button
              className='nav-toggle'
              onClick={() => setShowLinks(!showLinks)}>
              <div
                onClick={handleClick}
                className={hamburger ? 'x' : null}>
                <svg
                  id='Layer_1'
                  data-name='Layer 1'
                  xmlns='http://www.w3.org/2000/svg'
                  width='50'
                  height='50'
                  viewBox='-5 -3 40 30'>
                  <path
                    id='top'
                    className='line'
                    d='M166.18,102.56h25s7.52-.43,5-5.29c-2.89-5.65-8.7,2.07-8.7,2.07L169.84,117'
                    transform='translate(-166.18 -94.7)'
                  />
                  <path
                    id='bottom'
                    className='line'
                    d='M166.18,113.77h25s7.52.43,5,5.29c-2.89,5.64-8.7-2.07-8.7-2.07L169.84,99.31'
                    transform='translate(-166.18 -94.7)'
                  />
                  <line
                    id='middle'
                    className='line'
                    y1='13.48'
                    x2='25'
                    y2='13.48'
                    x1='0'
                  />
                </svg>
              </div>
            </button>
          </ul>
          <div
            className='links-container'
            ref={linksContainerRef}>
            <ul
              className='links linksbg'
              ref={linksRef}>
              <li onClick={handleClick}>
                <Link
                  onClick={scrallToTop}
                  to='/about'>
                  <div className={isArabic ? 'arFont divLink' : 'divLink'}>
                    {t('NAV_3')}
                  </div>
                </Link>
              </li>

              <li onClick={handleClick}>
                <Link
                  onClick={scrallToTop}
                  to='/projects'>
                  <div className={isArabic ? 'arFont divLink' : 'divLink'}>
                    {t('NAV_2')}
                  </div>
                </Link>
              </li>
              <li onClick={handleClick}>
                <Link
                  onClick={scrallToTop}
                  to='/contact'>
                  <div className={isArabic ? 'arFont divLink' : 'divLink'}>
                    {t('NAV_4')}
                  </div>
                </Link>
              </li>

              <li>
                <div className='themeContenerMobile'>
                  <div
                    className={
                      isArabic
                        ? 'themeContener themefixAR'
                        : 'themefix themeContener'
                    }>
                    <animated.svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='30'
                      height='30'
                      viewBox='0 0 24 24'
                      fill='#fff'
                      stroke={theme === 'light' ? '#1d1616' : '#fff'}
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      style={{ ...svgContainerProps, cursor: 'pointer' }}
                      onClick={switchTheme}>
                      <mask id='mask'>
                        <rect
                          x='0'
                          y='0'
                          width='100%'
                          height='100%'
                          fill='white'
                        />
                        <animated.circle
                          style={maskedCircleProps}
                          cx='12'
                          cy='4'
                          r='8'
                          fill='black'
                        />
                      </mask>
                      <animated.circle
                        style={centerCircleProps}
                        fill={theme === 'light' ? '#1d1616' : '#fff'}
                        cx='12'
                        cy='12'
                        r='9'
                        mask='url(#mask)'
                      />

                      <animated.g
                        style={linesProps}
                        fill='currentColor'>
                        <line
                          x1='12'
                          y1='1'
                          x2='12'
                          y2='3'
                        />
                        <line
                          x1='12'
                          y1='21'
                          x2='12'
                          y2='23'
                        />
                        <line
                          x1='4.22'
                          y1='4.22'
                          x2='5.64'
                          y2='5.64'
                        />
                        <line
                          x1='18.36'
                          y1='18.36'
                          x2='19.78'
                          y2='19.78'
                        />
                        <line
                          x1='1'
                          y1='12'
                          x2='3'
                          y2='12'
                        />
                        <line
                          x1='21'
                          y1='12'
                          x2='23'
                          y2='12'
                        />
                        <line
                          x1='4.22'
                          y1='19.78'
                          x2='5.64'
                          y2='18.36'
                        />
                        <line
                          x1='18.36'
                          y1='5.64'
                          x2='19.78'
                          y2='4.22'
                        />
                      </animated.g>
                    </animated.svg>
                  </div>
                </div>
              </li>
              <li>
                {isArabic ? (
                  <>
                    <div className='langBtnContener'>
                      <div className='langBtnContenerMobile'>
                        <div
                          className='langBtnDiv2'
                          onClick={chengLang}>
                          <div className=' langBtnDivEN'>A</div>
                        </div>
                        <div
                          className='langBtnDiv1'
                          onClick={chengLang}>
                          <div className='arFont langBtnDivAR'>ع</div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='langBtnContener'>
                      <div className='langBtnContenerMobile'>
                        <div
                          className='langBtnDiv2EN'
                          onClick={chengLang}>
                          <div
                            style={
                              theme === 'light'
                                ? { color: '#274eaa' }
                                : { color: '#315dc2' }
                            }
                            className='arFont langBtnDivAR'>
                            ع
                          </div>
                        </div>
                        <div
                          className='langBtnDiv1EN'
                          onClick={chengLang}>
                          <div
                            style={
                              theme === 'light'
                                ? { color: '#fff' }
                                : { color: '#1c1f26' }
                            }
                            className='langBtnDivEN'>
                            A
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

import { Suspense } from 'react';
import App from './App';
import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Loder from './components/Loder';

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar'],
    fallbackLng: 'ar',
    debug: false,
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
  });

const loadingMarkup = (
  <>
    <div className="loderContener">
      <Loder />
    </div>
  </>
);

export default (
  <Suspense fallback={loadingMarkup}>
    <App />
  </Suspense>
);

import { useState, useEffect } from 'react';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import List from '../assets/images/ab4.jpeg';
import Eye from '../assets/images/ab3.jpeg';
import DD1 from '../assets/images/dd1.png';
import DD2 from '../assets/images/dd2.png';
import DD3 from '../assets/images/dd3.png';
import Addres from '../assets/images/ab1.jpeg';
import Rocket from '../assets/images/ab2.jpeg';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function About() {
  const currentLanguageCode = cookies.get('i18next');

  const [isArabic, setIsArabic] = useState(
    currentLanguageCode === 'ar' ? true : false
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (currentLanguageCode === 'ar') {
      setIsArabic(true);
    } else {
      setIsArabic(false);
    }
  }, [currentLanguageCode, t]);

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 1000,
      easing: 'ease',
    });
  });

  return (
    <div className='standerContener aboutContener'>
      <div
        style={{ paddingTop: 5 }}
        className='aboutHeader'>
        <div
          data-aos='fade-down'
          className='cardiv'>
          <img
            className='searchIcon'
            src={Addres}
            alt='Addres'
          />
          <h2 className={isArabic ? 'arFont WelcomH2' : 'WelcomH2'}>
            {t('NAV_3')}
          </h2>
          <p
            className={isArabic ? 'arFont' : null}
            style={isArabic ? { direction: 'rtl' } : null}>
            {t('About1')}
          </p>
        </div>
      </div>
      <div className='aboutCardsContener2'>
        <div
          className='abbbbautDiv1'
          data-aos='fade-up'>
          {' '}
          <img
            className='searchIcon'
            src={Rocket}
            alt='Rocket'
          />
          <h2 className={isArabic ? 'arFont WelcomH2' : 'WelcomH2'}>
            {t('About2')}
          </h2>
          <p
            className={isArabic ? 'arFont' : null}
            style={isArabic ? { direction: 'rtl' } : null}>
            {t('About3')}
          </p>
        </div>
        <div
          data-aos='fade-up'
          className='abbbbautDiv1'>
          {' '}
          <img
            className='searchIcon'
            src={Eye}
            alt='Eye'
          />
          <h2 className={isArabic ? 'arFont WelcomH2' : 'WelcomH2'}>
            {t('About4')}
          </h2>
          <p
            className={isArabic ? 'arFont' : null}
            style={isArabic ? { direction: 'rtl' } : null}>
            {t('About5')}
          </p>
        </div>
      </div>
      <div
        data-aos='fade-up'
        className='aboutCardsContener cardiv'>
        <div className='aboutCardsOneS '>
          <div className='cardiv aboutDiv aboutDivCenter'>
            <img
              className='aboutSVG'
              src={List}
              alt='ThumbsUp'
            />
            <h2
              style={{ color: '#fff' }}
              className={isArabic ? 'arFont WelcomH2' : 'WelcomH2'}>
              {t('About6')}
            </h2>
          </div>
        </div>

        <div
          data-aos='fade-right'
          className='aboutCardsThree aboutCardsThreeFix'>
          <div className='cardiv aboutDiv'>
            <h2
              style={{ fontSize: 16 }}
              className={isArabic ? 'arFont WelcomH2' : 'WelcomH2'}>
              {t('About7')}
            </h2>
          </div>
          <div className='cardiv aboutDiv'>
            <h2
              style={{ fontSize: 16 }}
              className={isArabic ? 'arFont WelcomH2' : 'WelcomH2'}>
              {t('About8')}
            </h2>
          </div>
          <div
            data-aos='fade-right'
            className='cardiv aboutDiv'>
            <h2
              style={{ fontSize: 16 }}
              className={isArabic ? 'arFont WelcomH2' : 'WelcomH2'}>
              {t('About9')}
            </h2>
          </div>
        </div>
        <div className='aboutCardsOne '>
          <div className='cardiv aboutDiv aboutDivCenter'>
            <img
              className='aboutSVG'
              src={List}
              alt='ThumbsUp'
            />

            <h2
              style={{ color: '#fff' }}
              className={isArabic ? 'arFont WelcomH2' : 'WelcomH2'}>
              {t('About11')}
            </h2>
          </div>
        </div>
        <div className='aboutCardsThree '>
          <div
            data-aos='fade-left'
            className='cardiv aboutDiv'>
            <h2
              style={
                isArabic ? { direction: 'rtl', fontSize: 14 } : { fontSize: 14 }
              }
              className={isArabic ? 'arFont WelcomH2' : 'WelcomH2'}>
              {t('About12')}
            </h2>
          </div>
          <div
            data-aos='fade-left'
            className='cardiv aboutDiv'>
            <h2
              style={{ fontSize: 16 }}
              className={isArabic ? 'arFont WelcomH2' : 'WelcomH2'}>
              {t('About14')}
            </h2>
          </div>
          <div
            data-aos='fade-left'
            className='cardiv aboutDiv'>
            <h2
              style={{ fontSize: 16 }}
              className={isArabic ? 'arFont WelcomH2' : 'WelcomH2'}>
              {t('About15')}
            </h2>
          </div>
        </div>
      </div>

      {/* wndklwemnmfcl;we;fweml;wm */}

      <div className='aboutCardsContener2'>
        <div
          className='abbbbautDiv1'
          data-aos='fade-up'>
          {' '}
          <img
            className='searchIcon'
            src={DD2}
            alt='team'
          />
          <h2 className={isArabic ? 'arFont WelcomH2' : 'WelcomH2'}>
            {t('About16')}
          </h2>
          <p
            className={isArabic ? 'arFont' : null}
            style={isArabic ? { direction: 'rtl' } : null}>
            {t('About17')}
          </p>
        </div>
        <div
          data-aos='fade-up'
          className='abbbbautDiv1'>
          {' '}
          <img
            className='searchIcon'
            src={DD1}
            alt='client'
          />
          <h2 className={isArabic ? 'arFont WelcomH2' : 'WelcomH2'}>
            {t('About18')}
          </h2>
          <p
            className={isArabic ? 'arFont' : null}
            style={isArabic ? { direction: 'rtl' } : null}>
            {t('About19')}
          </p>
        </div>
      </div>
      <div className='shrkaVCOMM'>
        <div
          className='cardiv'
          data-aos='fade-up'>
          <img
            className='searchIcon'
            src={DD3}
            alt='massge'
          />
          <h2 className={isArabic ? 'arFont WelcomH2' : 'WelcomH2'}>
            {t('About26')}
          </h2>
          <p
            className={isArabic ? 'arFont' : null}
            style={isArabic ? { direction: 'rtl' } : null}>
            {t('About27')}
          </p>
        </div>
      </div>
    </div>
  );
}

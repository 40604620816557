// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import BannerOnw from '../assets/images/pr13.jpeg';
import BannerTow from '../assets/images/pr14.jpeg';
import BannerThree from '../assets/images/pr15.jpeg';
import Bannerfore from '../assets/images/pr16.jpeg';
import Bannerfive from '../assets/images/pr17.jpeg';
import Bannersix from '../assets/images/pr18.jpeg';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper';

export default function Gallery3() {
  return (
    <div className="galleryContener">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img className="galleryImg" src={BannerOnw} alt="galleryImg" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="galleryImg" src={BannerTow} alt="galleryImg" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="galleryImg" src={BannerThree} alt="galleryImg" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="galleryImg" src={Bannerfore} alt="galleryImg" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="galleryImg" src={Bannerfive} alt="galleryImg" />
        </SwiperSlide>{' '}
        <SwiperSlide>
          <img className="galleryImg" src={Bannersix} alt="galleryImg" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

import { useEffect, useState } from 'react';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../App.css';
import Banner from '../components/Banner';
// import Partners from '../components/Partners';
import LandingProject from '../components/LandingProject';
import Illustrations from '../assets/svg/chilling.svg';

import { Link } from 'react-router-dom';

export default function Landing({ theme }) {
  const currentLanguageCode = cookies.get('i18next');

  const [isArabic, setIsArabic] = useState(
    currentLanguageCode === 'ar' ? true : false
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (currentLanguageCode === 'ar') {
      setIsArabic(true);
    } else {
      setIsArabic(false);
    }
  }, [currentLanguageCode, t]);

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 1000,
      easing: 'ease',
    });
  });

  const scrallToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <div>
      <div className='BannerBg'>
        <div data-aos='fade-down'>
          <Banner />
        </div>
      </div>
      <div className='fade-fix'></div>

      <div className='body-wrap'>
        <header className='site-header'></header>

        <main>
          <section
            data-aos='fade-up'
            data-aos-duration='30000'
            className='hero'>
            <div className='container'>
              <div
                style={isArabic ? { direction: 'rtl' } : null}
                className='hero-inner'>
                <div
                  className={isArabic ? 'hero-copyAR' : 'hero-copy'}
                  style={isArabic ? { direction: 'rtl' } : null}>
                  <div
                    className={isArabic ? 'arFont hero-title' : 'hero-title '}
                    data-aos='fade-up'>
                    {t('LANDING1')}
                  </div>
                  <div
                    className={
                      isArabic ? 'arFont hero-paragraph' : 'hero-paragraph'
                    }
                    style={isArabic ? { textAlign: 'right' } : null}
                    data-aos='fade-up'>
                    {t('LANDING2')}
                  </div>
                  <div className='hero-cta'>
                    <div
                      data-aos='fade-up'
                      className='introBtnContener'>
                      <Link
                        onClick={scrallToTop}
                        to='/contact'>
                        <button
                          className={
                            isArabic ? 'arFont  btn-hover' : ' btn-hover'
                          }
                          style={
                            isArabic ? { direction: 'rtl', fontSize: 15 } : null
                          }>
                          {t('NAV_4')}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  style={isArabic ? { transform: 'scaleX(-1)' } : null}
                  data-aos='fade-down'
                  className='hero-figure anime-element'>
                  <svg
                    className='placeholder'
                    width='528'
                    height='396'
                    viewBox='0 0 528 396'>
                    <rect
                      width='528'
                      height='396'
                      style={{ fill: 'transparent' }}></rect>
                  </svg>
                  <div
                    className='hero-figure-box hero-figure-box-01'
                    data-aos='fade-down'></div>
                  <div
                    className='hero-figure-box hero-figure-box-02'
                    data-aos='fade-down'></div>

                  <div
                    className='hero-figure-box hero-figure-box-04'
                    data-aos='fade-down'></div>
                  <div
                    className='hero-figure-box hero-figure-box-05'
                    // style={{
                    //   transform:
                    //     'scaleX(1) scaleY(1) perspective(500px) rotateY(-15deg) rotateX(8deg) rotateZ(-1deg)',
                    // }}
                    data-aos='fade-down'></div>
                  {isArabic ? (
                    <>
                      <img
                        id='freepik_stories-building-safety'
                        data-aos='fade-up'
                        src={Illustrations}
                        alt='logo'
                        width='100%'
                        height='100%'
                        style={{
                          position: 'absolute',
                          left: 10,
                          top: -35,
                          zIndex: 10,
                          transform: 'scaleX(-1)',
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        id='freepik_stories-building-safety'
                        data-aos='fade-up'
                        src={Illustrations}
                        alt='logo'
                        width='100%'
                        height='100%'
                        style={{
                          position: 'absolute',
                          left: 10,
                          top: -35,
                          zIndex: 10,
                        }}
                      />
                    </>
                  )}

                  <div
                    className='hero-figure-box hero-figure-box-06'
                    data-aos='fade-down'></div>
                  <div
                    className='hero-figure-box hero-figure-box-07'
                    data-aos='fade-down'></div>

                  <div
                    className='hero-figure-box hero-figure-box-08'
                    data-aos='fade-down'></div>
                  <div
                    className='hero-figure-box hero-figure-box-09'
                    data-aos='fade-down'></div>
                  <div
                    className='hero-figure-box hero-figure-box-10'
                    data-aos='fade-down'></div>
                </div>
              </div>
            </div>
          </section>

          <section className='features section'>
            <div className='container'>
              <div className='features-inner section-inner has-bottom-divider'>
                <div className='servicesHederContener'>
                  <div>
                    <h2
                      className={
                        isArabic
                          ? 'arFont servicesHederTitle'
                          : 'servicesHederTitle'
                      }
                      data-aos='fade-down'>
                      {t('LANDING4')}
                    </h2>
                  </div>
                  <div>
                    <div
                      style={
                        isArabic
                          ? {
                              direction: 'rtl',
                              maxWidth: '780px',
                              margin: '30px auto',
                            }
                          : { maxWidth: '800px', margin: '30px auto' }
                      }
                      className={
                        isArabic ? 'arFont hero-paragraph' : 'hero-paragraph'
                      }
                      data-aos='fade-up'>
                      {/* {t('LANDING5')} */}
                    </div>
                  </div>
                </div>

                <div className='features-wrap'>
                  <div
                    className='feature text-center is-revealing'
                    style={{ visibility: 'visible' }}
                    data-aos='zoom-in'>
                    <Link
                      onClick={scrallToTop}
                      to='/projects'>
                      <div className='feature-inner cardiv'>
                        <div>
                          <h4
                            className={
                              isArabic
                                ? 'arFont feature-title mt-16'
                                : 'feature-title mt-16'
                            }>
                            {t('LANDING6')}
                          </h4>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div
                    className='feature text-center is-revealing'
                    style={{ visibility: 'visible' }}
                    data-aos='zoom-in'>
                    <Link
                      onClick={scrallToTop}
                      to='/projects'>
                      <div className='feature-inner cardiv'>
                        <div>
                          <h4
                            className={
                              isArabic
                                ? 'arFont feature-title mt-16'
                                : 'feature-title mt-16'
                            }>
                            {t('LANDING7')}
                          </h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div
                    className='feature text-center is-revealing'
                    style={{ visibility: 'visible' }}
                    data-aos='zoom-in'>
                    <Link
                      onClick={scrallToTop}
                      to='/projects'>
                      <div className='feature-inner cardiv'>
                        <div>
                          <h4
                            className={
                              isArabic
                                ? 'arFont feature-title mt-16'
                                : 'feature-title mt-16'
                            }>
                            {t('services1')}
                          </h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div
                    className='feature text-center is-revealing'
                    style={{ visibility: 'visible' }}
                    data-aos='zoom-in'>
                    <Link
                      onClick={scrallToTop}
                      to='/projects'>
                      <div className='feature-inner cardiv'>
                        <div>
                          <h4
                            className={
                              isArabic
                                ? 'arFont feature-title mt-16'
                                : 'feature-title mt-16'
                            }>
                            {t('services2')}
                          </h4>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div
                    className='feature text-center is-revealing'
                    style={{ visibility: 'visible' }}
                    data-aos='zoom-in'>
                    <Link
                      onClick={scrallToTop}
                      to='/projects'>
                      <div className='feature-inner cardiv'>
                        <div>
                          <h4
                            className={
                              isArabic
                                ? 'arFont feature-title mt-16'
                                : 'feature-title mt-16'
                            }>
                            {t('LANDING8')}
                          </h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div
                    className='feature text-center is-revealing'
                    style={{ visibility: 'visible' }}
                    data-aos='zoom-in'>
                    <Link
                      onClick={scrallToTop}
                      to='/projects'>
                      <div className='feature-inner cardiv'>
                        <div>
                          <h4
                            className={
                              isArabic
                                ? 'arFont feature-title mt-16'
                                : 'feature-title mt-16'
                            }>
                            {t('LANDING9')}
                          </h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className='ProjectContenerDiv'>
            <div className='servicesHederContener'>
              <div>
                <h2
                  className={
                    isArabic
                      ? 'arFont servicesHederTitle'
                      : 'servicesHederTitle'
                  }
                  data-aos='fade-down'
                  style={{ marginBottom: '60px' }}>
                  {t('LANDING120')}
                </h2>
              </div>
            </div>
            <div
              data-aos='zoom-in'
              className='landingProjectContener'>
              <LandingProject theme={theme} />
            </div>
          </section>

          {/* <section className='ProjectContenerDiv'>
            <div className='servicesHederContener'>
              <h2
                className={
                  isArabic ? 'arFont servicesHederTitle' : 'servicesHederTitle'
                }
                data-aos='fade-down'
                style={{ marginBottom: '60px' }}
              >
                {t('LANDING14')}
              </h2>
            </div>
            <div data-aos='zoom-in'>
              <Partners />
            </div>
          </section> */}
        </main>
      </div>
    </div>
  );
}

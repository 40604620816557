// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import BannerOnw from '../assets/images/pr19.jpeg';
import BannerTow from '../assets/images/pr20.jpeg';
import BannerThree from '../assets/images/pr21.jpeg';
import Bannerfore from '../assets/images/pr22.jpeg';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper';

export default function Gallery4() {
  return (
    <div className='galleryContener'>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        navigation={true}
        modules={[Navigation]}
        className='mySwiper'
      >
        <SwiperSlide>
          <img className='galleryImg' src={BannerOnw} alt='galleryImg' />
        </SwiperSlide>
        <SwiperSlide>
          <img className='galleryImg' src={BannerTow} alt='galleryImg' />
        </SwiperSlide>
        <SwiperSlide>
          <img className='galleryImg' src={BannerThree} alt='galleryImg' />
        </SwiperSlide>
        <SwiperSlide>
          <img className='galleryImg' src={Bannerfore} alt='galleryImg' />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

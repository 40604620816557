import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import ban2 from '../assets/images/ban2.jpeg';
import ban3 from '../assets/images/ban3.jpeg';
import ban31 from '../assets/images/www1.jpeg';
import ban32 from '../assets/images/www2.jpeg';
import ban33 from '../assets/images/www3.jpeg';
import ban34 from '../assets/images/www4.jpeg';
import ban35 from '../assets/images/www5.jpeg';

import DD4 from '../assets/images/dd.mp4';

import SwiperCore, { Pagination, Autoplay } from 'swiper';

SwiperCore.use([Pagination, Autoplay]);

export default function Banner() {
  return (
    <div className='BannerContener'>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 76000,
          // delay: 60000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        className='mySwiper'>
        <SwiperSlide>
          <div className='banalImg'>
            <video
              src={DD4}
              width='100%'
              controls
              autoPlay
              muted
              playsInline
            />
          </div>

          {/* <img className="banalImg" src={ban1} alt="BannerImg" /> */}
        </SwiperSlide>
        <SwiperSlide>
          <img
            className='banalImg'
            src={ban2}
            alt='BannerImg'
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className='banalImg'
            src={ban3}
            alt='BannerImg'
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className='banalImg'
            src={ban31}
            alt='BannerImg'
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className='banalImg'
            src={ban32}
            alt='BannerImg'
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className='banalImg'
            src={ban33}
            alt='BannerImg'
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className='banalImg'
            src={ban34}
            alt='BannerImg'
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className='banalImg'
            src={ban35}
            alt='BannerImg'
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

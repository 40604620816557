import React from 'react';

export default function Loder() {
  return (
    <>
      <div className="LoderContainer">
        <div className="containerLoder">
          <div className="box1Loder"></div>
          <div className="box2Loder"></div>
          <div className="box3Loder"></div>
        </div>
      </div>
    </>
  );
}

import { useState, useEffect } from 'react';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import map from '../assets/images/map.jpeg';
import time from '../assets/images/time.jpeg';
import call from '../assets/images/call.jpeg';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Contact({ theme }) {
  const currentLanguageCode = cookies.get('i18next');

  const [isArabic, setIsArabic] = useState(
    currentLanguageCode === 'ar' ? true : false
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (currentLanguageCode === 'ar') {
      setIsArabic(true);
    } else {
      setIsArabic(false);
    }
  }, [currentLanguageCode, t]);

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 1000,
      easing: 'ease',
    });
  });

  return (
    <div className='standerContener aboutContener'>
      <h2
        data-aos='fade-down'
        className={isArabic ? 'arFont WelcomH2' : 'WelcomH2'}>
        {t('Contact4')}
      </h2>

      <div
        data-aos='fade-up'
        className='contactContener'>
        <div className='contactContenerL'>
          <form
            method='POST'
            className='rForm'
            action='https://formsubmit.co/sqr@alsuqur.sa'
            // after submiting the form, redirect to this page
            to='/'>
            <div className='flexForm'>
              <input
                style={isArabic ? { direction: 'rtl' } : null}
                className={isArabic ? 'arFont contactInput' : 'contactInput'}
                placeholder={isArabic ? 'الاسم' : 'Name'}
                label='Name'
                name='Name'
                type='Name'
                autoComplete='Name'
              />
              <input
                type='hidden'
                name='_next'
                value='http://localhost:3000/'
              />

              <input
                style={isArabic ? { direction: 'rtl' } : null}
                className={isArabic ? 'arFont contactInput' : 'contactInput'}
                placeholder={isArabic ? 'البريد الالكتروني' : 'Email'}
                label='Email'
                name='email'
                type='email'
                autoComplete='email'
              />
              <input
                style={isArabic ? { direction: 'rtl' } : null}
                className={isArabic ? 'arFont contactInput' : 'contactInput'}
                placeholder={isArabic ? 'رقم الجوال' : 'Phone Number'}
                label='Phone'
                name='Phone'
                type='Phone'
                autoComplete='Phone'
              />
            </div>
            <textarea
              type='text'
              name='text'
              required
              style={isArabic ? { direction: 'rtl' } : null}
              className={isArabic ? 'arFont textarea' : 'textarea'}
              placeholder={isArabic ? 'الرسالة' : 'Message'}></textarea>

            <br />

            <button
              className={isArabic ? 'arFont btn-hover' : 'btn-hover'}
              type='submit'>
              {t('Contact2')}
            </button>
          </form>
        </div>
        <div className='contactContenerR'>
          <div className='conactFilter'>
            <h3
              style={{ marginBottom: '30px' }}
              className={isArabic ? 'arFont' : null}>
              {t('NAV_4')}
            </h3>
          </div>
        </div>
      </div>
      <div className='flex'>
        <div
          data-aos='fade-right'
          className='cardiv flexDiv'>
          <img
            className='searchIcon'
            src={map}
            alt='Addres'
          />

          <h3 className={isArabic ? 'arFont' : null}>{t('Contact5')}</h3>

          <div className={isArabic ? 'arFont contactText' : 'contactText'}>
            {t('Contact6')}
          </div>
          <br />
        </div>
        <div
          data-aos='zoom-in'
          className='cardiv flexDiv'>
          <img
            className='searchIcon'
            src={call}
            alt='Addres'
          />

          <h3 className={isArabic ? 'arFont' : null}> {t('Contact3')}</h3>
          <div className='contactText'> +966555551663</div>
          <div className='contactText'>sqr@alsuqur.sa</div>
        </div>
        <div
          data-aos='fade-left'
          className='cardiv flexDiv'>
          <img
            className='searchIcon'
            src={time}
            alt='Addres'
          />
          <h3 className={isArabic ? 'arFont' : null}> {t('Contact8')}</h3>
          <div className={isArabic ? 'arFont contactText' : 'contactText'}>
            {t('Contact9')}
          </div>
          <div className={isArabic ? 'arFont contactText' : 'contactText'}>
            {t('Contact10')}
          </div>{' '}
        </div>
      </div>

      <div
        data-aos='zoom-in'
        className='mapContener'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3759.02371641455!2d41.9617701!3d19.583484600000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15e5790aced9b687%3A0x8a747f58a64f15b7!2z2KfZhNi12YLZiNixINin2YTYsNmD2YrYqSDZhNmE2KrYrNin2LHYqQ!5e0!3m2!1sen!2ssa!4v1724934892548!5m2!1sen!2ssa'
          width={'100%'}
          height={'100%'}
          title='map'
          referrerPolicy='no-referrer-when-downgrade'
          style={{ border: 0 }}
        />
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/pagination';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Mousewheel, Pagination } from 'swiper';
import pr1 from '../assets/images/pr1.jpeg';
import pr2 from '../assets/images/pr2.jpeg';
import pr3 from '../assets/images/pr5.jpeg';
import pr6 from '../assets/svg/Images-bro.svg';
import pr7 from '../assets/svg/Images-bro2.svg';
import { Link } from 'react-router-dom';

export default function LandingProject({ theme }) {
  const currentLanguageCode = cookies.get('i18next');

  const [isArabic, setIsArabic] = useState(
    currentLanguageCode === 'ar' ? true : false
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (currentLanguageCode === 'ar') {
      setIsArabic(true);
    } else {
      setIsArabic(false);
    }
  }, [currentLanguageCode, t]);

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 1000,
      easing: 'ease',
    });
  });

  const scrallToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <div className="BannerContener2">
      <Swiper
        direction={'vertical'}
        slidesPerView={1}
        spaceBetween={0}
        mousewheel={true}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        freeMode={{
          enabled: true,
          sticky: true,
        }}
        modules={[Mousewheel, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img className="projectImg" src={pr1} alt="projectImg" />
        </SwiperSlide>
        <SwiperSlide>
          {' '}
          <img className="projectImg" src={pr2} alt="projectImg" />
        </SwiperSlide>
        <SwiperSlide>
          {' '}
          <img className="projectImg" src={pr3} alt="projectImg" />
        </SwiperSlide>

        <SwiperSlide>
          {' '}
          <div className="projectImgBtnContener">
            {theme === 'light' ? (
              <>
                {' '}
                <img
                  src={pr6}
                  alt="projectImg"
                  width="100%"
                  height="100%"
                  style={{ marginTop: -90 }}
                />
              </>
            ) : (
              <>
                {' '}
                <img
                  src={pr7}
                  alt="projectImg"
                  width="100%"
                  height="100%"
                  style={{ marginTop: -90 }}
                />
              </>
            )}

            <Link
              className="projectImgBtn"
              onClick={scrallToTop}
              to="/projects"
            >
              <button
                className={isArabic ? 'arFont  btn-hover ' : ' btn-hover '}
                style={
                  isArabic
                    ? {
                        direction: 'rtl',
                        fontSize: 15,
                      }
                    : null
                }
              >
                {t('LANDING13')}
              </button>
            </Link>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing';
import NavBar from './components/NavBar';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import useLocalStorage from 'use-local-storage';
import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import ScrollToTopBtn from './components/ScrollToTop';

//
// ///
// /
//  linerrrrrrrrrrrr gridnt
//
// /
//
// /

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    country_code: 'sa',
  },
];

function App() {
  const currentLanguageCode = cookies.get('i18next');
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('app_title');
  }, [currentLanguage, t]);

  const getColorTheme = () => {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      return 'dark';
    } else {
      return 'light';
    }
  };
  const colorTheme = getColorTheme();
  const [theme, setTheme] = useLocalStorage('theme', colorTheme);

  const switchTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  return (
    <div
      className='app'
      data-theme={theme}>
      <Router>
        <NavBar
          theme={theme}
          switchTheme={switchTheme}
        />
        <div className='contenerAPP'>
          <Routes>
            <Route
              exact
              path='/'
              element={<Landing theme={theme} />}
            />
            <Route
              exact
              path='/ar'
              element={<Landing theme={theme} />}
            />
            <Route
              exact
              path='/en'
              element={<Landing theme={theme} />}
            />
            <Route
              exact
              path='/about'
              element={<About theme={theme} />}
            />

            <Route
              exact
              path='/projects'
              element={<Projects theme={theme} />}
            />
            <Route
              exact
              path='/contact'
              element={<Contact theme={theme} />}
            />
          </Routes>
        </div>
        <ScrollToTopBtn />
        <Footer theme={theme} />
      </Router>
    </div>
  );
}

export default App;

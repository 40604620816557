import React from 'react';
import { Link } from 'react-router-dom';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import SmartCloud from '../assets/svg/cloud.svg';
import SmartCloud2 from '../assets/svg/cloud2.svg';

export default function Footer({ theme }) {
  const currentLanguageCode = cookies.get('i18next');

  const [isArabic, setIsArabic] = useState(
    currentLanguageCode === 'ar' ? true : false
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (currentLanguageCode === 'ar') {
      setIsArabic(true);
    } else {
      setIsArabic(false);
    }
  }, [currentLanguageCode, t]);

  const Year = new Date().getFullYear();

  const scrallToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <div
      style={isArabic ? { direction: 'rtl' } : null}
      className='footerContener'>
      <div className='footers'>
        <div className='footersL'>
          <div className='footersL1'>
            <ul className='links'>
              <li>
                <Link
                  onClick={scrallToTop}
                  className='linkbtnn'
                  to='/about'>
                  <div
                    className={isArabic ? 'arFont footerLink' : 'footerLink'}>
                    {t('NAV_3')}
                  </div>
                </Link>
              </li>
              <li></li>
              <li>
                <Link
                  onClick={scrallToTop}
                  to='/projects'>
                  <div
                    className={isArabic ? 'arFont footerLink' : 'footerLink'}>
                    {t('NAV_2')}
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  onClick={scrallToTop}
                  to='/contact'>
                  <div
                    className={isArabic ? 'arFont footerLink' : 'footerLink'}>
                    {t('NAV_4')}
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <div className={isArabic ? 'arFont footersL2' : 'footersL2'}>
            {isArabic ? <>جميع الحقوق محفوظة</> : <>All Rights Reserved</>}
            <span
              style={isArabic ? { margin: '0 3px', marginBottom: -3 } : null}>
              &nbsp;©&nbsp;
            </span>{' '}
            {Year}&nbsp;
            {isArabic ? <>الصقور الذكية للتجارة</> : <>Smart Falcons Trading</>}
          </div>
        </div>
        <div className='footersR'>
          <div className='footersR1'>
            <ul className='social-icons'>
              <li>
                <a
                  href='https://twitter.com/'
                  rel='noreferrer'
                  target='_blank'>
                  <i className='fa-brands fa-twitter'></i>
                  <i></i>
                </a>
              </li>
              <li>
                <a
                  href='https://www.instagram.com/'
                  rel='noreferrer'
                  target='_blank'>
                  <i className='fa-brands fa-instagram'></i>
                </a>
              </li>
              <li>
                <a
                  href='https://www.youtube.com/'
                  rel='noreferrer'
                  target='_blank'>
                  <i className='fa-brands fa-youtube'></i>
                </a>
              </li>
              <li>
                <a
                  href='https://www.facebook.com/'
                  rel='noreferrer'
                  target='_blank'>
                  <i className='fa-brands fa-facebook-f'></i>
                </a>
              </li>
              <li>
                <a
                  href='https://api.whatsapp.com/send?phone=966555551663&text=السلام عليكم&source=&data=&app_absent='
                  rel='noreferrer'
                  target='_blank'>
                  <i className='fa-brands fa-whatsapp'></i>
                </a>
              </li>
              <li>
                <a
                  href='https://www.linkedin.com/'
                  rel='noreferrer'
                  target='_blank'>
                  <i className='fa-brands fa-linkedin-in'></i>
                </a>
              </li>
              <li>
                <a
                  href='https://www.snapchat.com/'
                  rel='noreferrer'
                  target='_blank'>
                  <i className='fa-brands fa-snapchat'></i>
                </a>
              </li>
              <li>
                <a
                  href='mailto:sqr@alsuqur.sa'
                  rel='noreferrer'
                  target='_blank'>
                  <i className='fa-regular fa-envelope'></i>
                </a>
              </li>
            </ul>
          </div>

          <div
            style={{ direction: 'ltr' }}
            className='footersR2'>
            <a
              className='footersR2Div'
              href='tel:+966555551663'>
              {' '}
              +966555551663{' '}
            </a>{' '}
            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span className={isArabic ? 'arFont footersR2Div' : 'footersR2Div'}>
              sqr@alsuqur.sa
            </span>
          </div>
        </div>
      </div>
      <div className='Powerd'>
        {' '}
        <div
          className='footerDiv'
          style={{ direction: 'ltr' }}>
          Powered By{' '}
          <a
            className='footerDiv'
            href='https://smartcloud.sa/'
            rel='noreferrer'
            target='_blank'>
            {theme === 'light' ? (
              <>
                <img
                  src={SmartCloud}
                  alt='SmartCloud'
                  style={{
                    marginLeft: 5,
                  }}
                  width='30px'
                  height='30px'
                />
              </>
            ) : (
              <>
                <img
                  src={SmartCloud2}
                  alt='SmartCloud'
                  style={{
                    marginLeft: 5,
                  }}
                  width='30px'
                  height='30px'
                />
              </>
            )}
            <div className='smartCloudContener'>
              <div className='smartCloudAR'>سمارت كلاود</div>
              <div className='smartCloudEN'>Smart Cloud</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
